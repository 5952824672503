import { Controller } from "@hotwired/stimulus";
import { FetchRequest, get } from "@rails/request.js"
import { MarkerClusterer } from "@googlemaps/markerclusterer";

// Connects to data-controller="map"
export default class extends Controller {
  static targets = ["map"];

  static values = {
    model: String,
    profile: String,
    objectId: Number,
    apiKey: String
  }

  connect() {
    this.loadGoogleMaps();
  }

  loadGoogleMaps() {
    if (typeof google !== "undefined" && google.maps) {
      // console.log("Google Maps API already loaded.");
      this.fetchData();
      return;
    }

    const existingScript = document.querySelector('script[src*="maps.googleapis.com/maps/api/js"]');
    if (existingScript) {
      // console.log("Google Maps API script tag already exists.");
      this.fetchData();
      return;
    }

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKeyValue}&v=weekly&libraries=marker&loading=async`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    // console.log("Google Maps API script added.");

    this.fetchData();
  }

  async fetchData() {
    try {
      const request = new FetchRequest('GET', format("/profiles/{0}/geo_locations/index?model={1}&id={2}", this.profileValue, this.modelValue, this.objectIdValue), {
        responseKind: "json"
      });
      const response = await request.perform();
  
      if (response.ok) {
        const body = await response.text;
        const data = JSON.parse(body);
        this.initMap(data['locations'], data['translations'])
      } else {
        console.error('Request failed:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error making GET request:', error);
    }
  }

  initMap(geopoints, translations) {
    const middlePoint = this.calculateMiddlePoint(geopoints);

    const map = new google.maps.Map(document.getElementById("map"), {
      zoom: 3,
      center: middlePoint,
      streetViewControl: false,
      disableDefaultUI: true,
      scaleControl: true,
      zoomControl: true,
      mapId: "68660f8d451e60b6",
    });

    google.maps.event.addListenerOnce(map, "idle", () => {
      this.addMarkers(map, geopoints, translations);
    });
  }


  addMarkers(map, points, translations) {
    const markers = points.map((location) => {
      const marker = new google.maps.marker.AdvancedMarkerElement({
          map,
          position: { lat: location.lat, lng: location.lng },
          title: location.name
      });

      marker.addListener("click", () => {
        this.showCustomInfoWindow(map, marker, location, translations);
      });
  
      return marker;
    });
  
    new MarkerClusterer({ markers, map });
  }

  showCustomInfoWindow(map, marker, location, translations) {
    // Remove any existing custom InfoWindow
    const existingWindow = document.querySelector(".custom-info-window");
    if (existingWindow) existingWindow.remove();
  
    // Create a custom InfoWindow element
    const infoWindowDiv = document.createElement("div");
    infoWindowDiv.className = "custom-info-window";
    infoWindowDiv.style.position = "absolute";
    infoWindowDiv.style.background = "white";
    infoWindowDiv.style.border = "1px solid #ccc";
    infoWindowDiv.style.padding = "10px";
    infoWindowDiv.style.borderRadius = "8px";
    infoWindowDiv.style.boxShadow = "0 2px 6px rgba(0, 0, 0, 0.3)";
    infoWindowDiv.style.minWidth = "200px";
    infoWindowDiv.style.minHeight = "100px";
  
    // Set content with title and close button on the same line
    infoWindowDiv.innerHTML = `
      <div style="display: flex; justify-content: space-between; align-items: center;">
        ${location.link 
          ? `<a href="${location.link}" target="_blank" style="font-weight: bold; font-size: 14px; color: #007BFF; text-decoration: none;">${location.name}</a>` 
          : `<strong>${location.name}</strong>`}
        <button style="border: none; background: none; font-size: 20px; cursor: pointer;" onclick="this.parentElement.parentElement.remove();">
          &times;
        </button>
      </div>
      ${location.info_one ? `<div>${translations.info_one}: ${location.info_one}</div>` : ""}
      ${location.info_two ? `<div>${translations.info_two}: ${location.info_two}</div>` : ""}
      ${location.info_three ? `<div>${translations.info_three}: ${location.info_three}</div>` : ""}
    `;

      
    // Append the InfoWindow to the map
    map.getDiv().appendChild(infoWindowDiv);
  
    // Position the InfoWindow above the marker
    infoWindowDiv.style.left = '10px';
    infoWindowDiv.style.top = '10px';
  }

  calculateMiddlePoint(locations) {
    if (!locations || locations.length === 0) {
      return { lat: 0, lng: 0 };
    }
  
    let totalLat = 0;
    let totalLng = 0;
  
    locations.forEach((location) => {
      totalLat += location.lat;
      totalLng += location.lng;
    });
  
    const middleLat = totalLat / locations.length;
    const middleLng = totalLng / locations.length;
  
    return { lat: middleLat, lng: middleLng };
  }
}
