import { Controller } from "stimulus"
import interact from 'interactjs'

export default class extends Controller {

  static targets = [
    "draggableZone",
    "submitBtn",
    "statusIndicator",
    "form",
    "leftPx",
    "topPx",
    "widthPx",
    "heightPx"
  ]

  initialize() {
    this.draggableZoneTargets.forEach(element => {
      this.draggableZone(element)
      this.populatePxInputs(element)
    });
    
    if(window.innerWidth > 767){
      this.calculateMainSectionHeight();
    }
  }

  calculateMainSectionHeight(){
    const headerHeight = document.getElementById('site-header').getBoundingClientRect().height;
    const mainPageStyles = window.getComputedStyle(document.getElementById('main-page').firstElementChild);
    const mainPageTopPadding = parseInt(mainPageStyles.paddingTop);
    const mainPageBottomPadding = parseInt(mainPageStyles.paddingBottom);
    const topBarHeight = document.getElementById('main-page').firstElementChild.firstElementChild.getBoundingClientRect().height;

    const usedSpace = headerHeight + mainPageTopPadding + mainPageBottomPadding + topBarHeight + 15; //Last 15px are from the main-page element bottom padding
    document.getElementById('main-section').style.height = "calc(100vh - " + usedSpace + "px)";
  }

  populatePxInputs(element){
    const zoneFields = document.getElementById(`fields-zone-${element.dataset.zoneId}`);
    const layoutRatio = zoneFields.querySelector('.layout-ratio-values');
    const layoutWidth = layoutRatio.dataset.width;
    const layoutHeight = layoutRatio.dataset.height;

    const left = zoneFields.querySelector('.left-input');
    const leftPx = zoneFields.querySelector('.left-input-px');
    leftPx.value = Math.round((left.value * layoutWidth) / 100.0)

    const top = zoneFields.querySelector('.top-input');
    const topPx = zoneFields.querySelector('.top-input-px');
    topPx.value = Math.round((top.value * layoutHeight) / 100.0)

    const width = zoneFields.querySelector('.width-input');
    const widthPx = zoneFields.querySelector('.width-input-px');
    widthPx.value = Math.round((width.value * layoutWidth) / 100.0)

    const height = zoneFields.querySelector('.height-input');
    const heightPx = zoneFields.querySelector('.height-input-px');
    heightPx.value = Math.round((height.value * layoutHeight) / 100.0)
  }

  updatePxInputs(element){;
    const zoneFields = document.getElementById(`fields-zone-${element.dataset.zoneId}`)
    const layoutRatio = zoneFields.querySelector('.layout-ratio-values');
    const layoutWidth = layoutRatio.dataset.width;
    const layoutHeight = layoutRatio.dataset.height;
    const zoneId = layoutRatio.dataset.zoneId;

    const left = zoneFields.querySelector('.left-input');
    const leftPx = zoneFields.querySelector('.left-input-px');
    left.value = (leftPx.value * 100.0) / layoutWidth;

    const top = zoneFields.querySelector('.top-input');
    const topPx = zoneFields.querySelector('.top-input-px');
    top.value = (topPx.value * 100.0) / layoutHeight;

    const width = zoneFields.querySelector('.width-input');
    const widthPx = zoneFields.querySelector('.width-input-px');
    width.value = (widthPx.value * 100.0) / layoutWidth;

    const height = zoneFields.querySelector('.height-input');
    const heightPx = zoneFields.querySelector('.height-input-px');
    height.value = (heightPx.value * 100.0) / layoutHeight;
  }

  draggableZone(element) {
    const position = { x: 0, y: 0 }
    const controller = this;

    interact(`#${element.id}`).draggable({
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: 'parent'
        }),
        interact.modifiers.snap({
          targets: [
            interact.snappers.grid({ x: 5, y: 5 })
          ],
          range: Infinity,
          relativePoints: [ { x: 0, y: 0 } ]
        })
      ],
      listeners: {
        move (event) {
          // keep the dragged position in the data-x/data-y attributes
          position.x = (parseFloat(event.target.getAttribute('data-x')) || 0) + event.dx,
          position.y = (parseFloat(event.target.getAttribute('data-y')) || 0) + event.dy;

          event.target.style.transform = `translate(${position.x}px, ${position.y}px)`;

          controller.updatePositionAttributes(event.target, element, position.x, position.y)
        },
      }
    }).resizable({
      margin: 5,
      edges: { top: true, left: true, bottom: true, right: true },
      listeners: {
        move: function (event) {
          let { x, y } = event.target.dataset

          x = (parseFloat(x) || 0) + event.deltaRect.left
          y = (parseFloat(y) || 0) + event.deltaRect.top

          Object.assign(event.target.style, {
            width: `${event.rect.width}px`,
            height: `${event.rect.height}px`,
            transform: `translate(${x}px, ${y}px)`
          })

          Object.assign(event.target.dataset, { x, y })

          controller.updateSizeAttributes(event.target, element)
          controller.updatePositionAttributes(event.target, element, x, y)
        }
      }
    })
  }

  updateSizeAttributes(target, element) {
    const zoneFields = document.getElementById(`fields-zone-${element.dataset.zoneId}`);
    const layoutRatio = zoneFields.querySelector('.layout-ratio-values');
    const layoutWidth = parseFloat(layoutRatio.dataset.width);
    const layoutHeight = parseFloat(layoutRatio.dataset.height);

    var relativeWidth = (target.offsetWidth / target.parentElement.offsetWidth * 100).toFixed(2)
    var relativeHeight = (target.offsetHeight / target.parentElement.offsetHeight * 100).toFixed(2)

    
    var widthInputPx = zoneFields.querySelector('.width-input-px');
    var heightInputPx = zoneFields.querySelector('.height-input-px');
    var widthInput = zoneFields.querySelector('.width-input');
    var heightInput = zoneFields.querySelector('.height-input');

    widthInputPx.value = Math.round((relativeWidth / 100.0) * layoutWidth)
    heightInputPx.value = Math.round((relativeHeight / 100.0) * layoutHeight)
    widthInput.value = relativeWidth
    heightInput.value = relativeHeight

    this.enableSubmit();
  }

  updatePositionAttributes(target, element, positionX, positionY) {
    const zoneFields = document.getElementById(`fields-zone-${element.dataset.zoneId}`);
    const layoutRatio = zoneFields.querySelector('.layout-ratio-values');
    const layoutWidth = parseFloat(layoutRatio.dataset.width);
    const layoutHeight = parseFloat(layoutRatio.dataset.height);

    const relativePosition = { left: 0, top: 0 }

    relativePosition.left = (parseFloat(target.style.left) + (positionX / target.parentElement.offsetWidth * 100)).toFixed(2)
    relativePosition.top = (parseFloat(target.style.top) + (positionY / target.parentElement.offsetHeight * 100)).toFixed(2)

    target.dataset.x = positionX
    target.dataset.y = positionY
    
    var leftInputPx = zoneFields.querySelector('.left-input-px');
    var topInputPx = zoneFields.querySelector('.top-input-px');
    var leftInput = zoneFields.querySelector('.left-input');
    var topInput = zoneFields.querySelector('.top-input');

    leftInputPx.value = Math.round((relativePosition.left / 100.0) * layoutWidth)
    leftInput.value = relativePosition.left
    topInputPx.value = Math.round((relativePosition.top / 100.0) * layoutHeight)
    topInput.value = relativePosition.top

    this.enableSubmit();
  }

  updateZone() {
    this.updatePxInputs(event.currentTarget);
    this.checkInputValue(event.currentTarget)

    const elementFields = event.currentTarget.closest(".zone-editing-fields");
    const elementId = elementFields.dataset.targetId;
    const element = document.getElementById(elementId);

    const leftInput = elementFields.querySelector('.left-input');
    const topInput = elementFields.querySelector('.top-input');
    const widthInput = elementFields.querySelector('.width-input');
    const heightInput = elementFields.querySelector('.height-input');
    
    element.style.left = leftInput.value + '%';
    element.style.top = topInput.value + '%';
    element.style.width = widthInput.value + '%';
    element.style.height = heightInput.value + '%';
    
    this.enableSubmit();
  }

  toggleZoneFields(){
    this.hideZoneFields();
    let zoneId = event.currentTarget.dataset.zoneId;

    this.draggableZoneTargets.forEach(element => {
      element.classList.remove('ring');
      if (element.dataset.zoneId === zoneId ){ element.classList.add('ring'); }
    });

    const fieldsForm = document.getElementById(`fields-zone-${zoneId}`);
    if (fieldsForm){ fieldsForm.classList.remove('hidden'); }

    const thumbBtn = document.getElementById(`zone-thumb-btn-${zoneId}`);
    if (thumbBtn){ thumbBtn.classList.add('ring', 'bg-blue-100'); }
  }

  hideZoneFields(){
    document.querySelectorAll('.zone-fields').forEach(target => {
      target.classList.add('hidden');
    });

    document.querySelectorAll('.zone-thumb-btn').forEach(target => {
      target.classList.remove('ring', 'bg-blue-100');
    });
  }

  disablePageLinks(){
    const links = document.links;

    for (var i = 0; i < links.length; i++) {
      links[i].onclick = function() {return false;};
      links[i].setAttribute('disabled', 'disabled');
      links[i].setAttribute('data-action', '');
    }
  }

  enableSubmit(){
    this.submitBtnTarget.disabled = false;

    const statusIdicator = this.statusIndicatorTarget;
    const bubble = statusIdicator.querySelector('#status-bubble');
    
    statusIdicator.classList.remove('opacity-60');
    bubble.classList.remove('bg-gray-500')
    bubble.classList.add('bg-yellow-300');
  }

  submitForm(event){
    event.preventDefault();
    this.disablePageLinks();
    this.formTarget.requestSubmit();
  }

  checkInputValue(target) {
    if (!(target.value <= parseInt(target.max) && target.value >= 0)){
      target.value = parseInt(target.max)
    }
  }
}