import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    "newSmartSearchForm",
    "entriesContainer",
    "entry",
    "hashtagInputContainer",
    "handleInputContainer",
    "switchStatus",
    "showMoreButton",
    "showLessButton"
  ];

  static values = {
    searchType: { type: String, default: "" },
    keywordFilterExclude: { type: String, default: "exclude" },
    keywordFilterInclude: { type: String, default: "include" },
    paginationOffset: { type: Number, default: 10 }
  }

  initialize() {
    this.searchEntriesPaginationOffset = this.paginationOffsetValue;
  }

  submitSearchForm() {
    const activeNetworkTab = document.querySelector("[data-tabs-target='tab']:not(.hidden)");
    const searchType = activeNetworkTab.dataset.targetId.split("-")[1];
    
    this.newSmartSearchFormTargets.forEach(form => {
      if (form.id.endsWith(searchType)) {
        if (searchType == "social_image") {
          this.emptyEntriesContainer();
        }
        else {
          form.requestSubmit();
        }
      }
    });
  }

  emptyEntriesContainer() {
    this.entriesContainerTarget.innerHTML = "";
  }

  toggleHashtagInput(event) {
    if (event.target.value === "#") {
      const activeNetworkTab = document.querySelector("[data-tabs-target='tab']:not(.hidden)");
      const searchType = activeNetworkTab.dataset.targetId.split("-")[1];

      this.hashtagInputContainerTargets.forEach(input => {
        if (input.id == searchType) {
          input.classList.remove("hidden");
        }
      });

      this.handleInputContainerTarget.classList.add("hidden");
      this.handleInputContainerTarget.querySelector("input").value = "";
    }
    else if (event.target.value === "@") {
      this.handleInputContainerTarget.classList.remove("hidden");

      this.hashtagInputContainerTargets.forEach(input => {
        input.classList.add("hidden");
        input.querySelector("input").value = "";
      });
    } 
    else {
      this.hashtagInputContainerTargets.forEach(input => {
        input.classList.add("hidden");
        input.querySelector("input").value = "";
      });
      
      this.handleInputContainerTarget.classList.add("hidden");
      this.handleInputContainerTarget.querySelector("input").value = "";

      this.submitSearchForm();
    }
  }

  toggleSwitchStatus() {
    if (this.switchStatusTarget.innerText == this.keywordFilterIncludeValue) {
      this.switchStatusTarget.innerText = this.keywordFilterExcludeValue;
      this.switchStatusTarget.classList.add("text-blue-400");
      this.switchStatusTarget.classList.remove("text-gray-400");
    }
    else {
      this.switchStatusTarget.innerText = this.keywordFilterIncludeValue;
      this.switchStatusTarget.classList.add("text-gray-400");
      this.switchStatusTarget.classList.remove("text-blue-400");
    }
  }

  showMore() {
    this.searchEntriesPaginationOffset += this.paginationOffsetValue;
    this.updateEntries();
  }

  showLess() {
    if (this.searchEntriesPaginationOffset - this.paginationOffsetValue > 0) {
      this.searchEntriesPaginationOffset = Math.max(this.paginationOffsetValue, this.searchEntriesPaginationOffset - this.paginationOffsetValue);
      this.updateEntries();
    }
  }

  updateEntries() {
    this.entryTargets.forEach((entry, index) => {
      entry.classList.toggle("hidden", index >= this.searchEntriesPaginationOffset);
    });

    const hasMore = this.searchEntriesPaginationOffset < this.entryTargets.length;
    const hasLess = this.searchEntriesPaginationOffset > this.paginationOffsetValue;

    if (this.hasShowMoreButtonTarget) {
      this.showMoreButtonTarget.disabled = !hasMore;
    }

    if (this.hasShowLessButtonTarget) {
      this.showLessButtonTarget.disabled = !hasLess;
    }
  }
}