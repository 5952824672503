import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["toggleableInput", "toggleableBlock"]

  connect() {
    // Initial visibility check of all inputs when controller connects
    this.toggleAllBlocks();
    this.toggleAllInputs();
  }

  toggleAllInputs(){
    this.toggleableInputTargets.forEach(element => {
      if(element.dataset.formFlowAffectsInputs && element.dataset.formFlowToggleInputsWhen){
        this.toggleInput(element);
      }
    })
  }

  toggleAllBlocks(){
    this.toggleableInputTargets.forEach(element => {
      if(element.dataset.formFlowAffectsBlocks && element.dataset.formFlowToggleBlocksWhen){
        this.toggleBlock(element);
      }
    })
  }

  toggleInput(element){
    element = element.target? element.target : element;
    const objectives = element.dataset.formFlowAffectsInputs?.split(';') || [];
    const conditions = element.dataset.formFlowToggleInputsWhen?.split(';') || [];
    if(objectives != undefined && objectives != '' && conditions != undefined && conditions != ''){
      const shouldShow = this.shouldShow(conditions)

      objectives.forEach(objective => {
        const objectiveWrapper = this.toggleableInputTargets.find(input => input.id === objective).closest('.form_group');
        shouldShow ? objectiveWrapper.classList.remove('hide') : objectiveWrapper.classList.add('hide');
      })
    }
  }

  toggleBlock(element){
    element = element.target? element.target : element;
    const objectives = element.dataset.formFlowAffectsBlocks?.split(';') || [];
    const conditions = element.dataset.formFlowToggleBlocksWhen?.split(';') || [];
    if(objectives != undefined && objectives != '' && conditions != undefined && conditions != ''){
      const shouldShow = this.shouldShow(conditions)

      objectives.forEach(objective => {
        const objectiveBlock = this.toggleableBlockTargets.find(block => block.id === objective)
        shouldShow ? objectiveBlock.classList.remove('hide') : objectiveBlock.classList.add('hide');
      })
    }
  }
  
  shouldShow(conditions){
    return conditions.every(condition => {
      const [conditionInputId, conditionInputValue] = [condition.split(':')[0], this.cleanValue(condition.split(':')[1])]
      const sourceInput = this.toggleableInputTargets.find(input => input.id === conditionInputId)
      const sourceInputValue = this.getCleanSourceValue(sourceInput);

      return sourceInput && sourceInputValue === conditionInputValue
    })
  }

  getCleanSourceValue(input){
    switch(input.type) {
      case 'checkbox':
        return input.checked;    
      case 'radio':
        return input.checked ? input.value : null;
      default:
        return input.value || null;
    }
  }

  cleanValue(value){
    const normalizedValue = value.toLowerCase()
    
    switch (normalizedValue) {
        case 'true':
            return true
        case 'false':
            return false
        default:
            return normalizedValue
    }
  }
}